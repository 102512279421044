import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import hands from '../../images/gallery_2.png';
import google from '../../images/google.webp';
import ios from '../../images/ios.webp';
import tv from '../../images/tv_1.png';
import Game from '../Game';

const HomeContainer = styled.div`
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  background-color: ${colors.homeBackground};
  display: flex;
  flex-direction: column;
  padding: 0 44px;
  align-items: center;
  overflow: hidden;
  margin-bottom: 100px;
  
`;

const Home = () => {
  return (
    <HomeContainer id="section-main">
      <Game
        title="Ball Escape"
        
        googleSrc={google}
        iosSrc={ios}
        quote="Our hyper-casual game soon to be released!"
        pic={hands}
        comingSoon={false}
      />
      <Game
       
      />

    </HomeContainer>
  )
}

export default Home;