export const colors = {
  navbarBackground: '#cdf1ff',
  menuLink: '#3b3737',
  logo: '#3b3737',
  homeBackground: '#FFF',
  aboutBackground: '#FFF',
  contactBackground: '#FFF',
  homeTitle: '#410fa3',
  aboutTitle: '#3b3737',
  contactTitle: '#3b3737',
  mailTitle: '#000000',
  comingSoonBG: '#00bbff',
  soonText: '#ff5c5c',
  //storyText: '#006700',
}


// logo: '#11caa0',
//aboutBackground: '#410fa3',